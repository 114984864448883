import React from 'react'
import { Outlet} from 'react-router-dom'
import HeaderTraveller from '../../components/HeaderTraveller'

import CompanyFooter2 from '../../../Company/components/CompanyFooter2'



const Layout2 = () => {
  return (
    <div>
      <HeaderTraveller/>
      <main>
        <Outlet/>
      </main>
      <CompanyFooter2/>
    </div>
  )
}

export default Layout2
