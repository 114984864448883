import React from 'react'
import "./travellerTracking.css"
import busIcon from "../../images/bus.png"
import checkIcon from "../../images/checkcircle.png"
import { RiArrowRightSLine } from "react-icons/ri";



const TravellerTracking = () => {
  
    return (
    <div className='travellerTracking-mainContainer'>
        <div className="travellerTracking-btnHolder">
            <h3>Track your Trip</h3>
            <input type="text" placeholder='Enter Ticket ID' />
        </div>
        <div className="tickerId-details-holder">
            <h5>Ticket ID: GIGM286GD</h5>
            <div className="ticketID-details">
                <div className="ticketID-details-part1">
                    <div className="ticketID-details-part1a">
                        <img src={busIcon} alt="" />
                        <h5>LAG</h5>
                        <h5>6:00 AM</h5>
                    </div>
                    <div className="part1Dot-holder">
                        <div className="PartDot"></div>
                    </div>
                </div>

                <div className="ticketID-details-part2">
                    <img src={checkIcon} alt="" />
                </div>

                <div className="ticketID-details-part1">
                <div className="part1Dot-holder2">
                        <div className="PartDot"></div>
                    </div>
                    <div className="ticketID-details-part1a">
                        <img src={busIcon} alt="" />
                        <h5>LAG</h5>
                        <h5>6:00 AM</h5>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="ticketID-detais">
            <div className="ticketId-details-title">
                <h4>Trip details</h4> <RiArrowRightSLine />
            </div>
            <div className="ticketID-detais-item1">
                <div className="ticketID-detais-item1a">
                    <img src={busIcon} alt="" />
                    <h4>Departed Terminal: Abuja Terminal, Kubwa</h4>
                </div>
                <p>6:00 AM</p>
            </div>
        </div>
    </div>
  )
}

export default TravellerTracking