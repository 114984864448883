import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { viewTicketsAdmin } from '../../../../../../actions/authActions'
import "./Tickets.css"
import DashboardHeader from '../../components/DashboardHeader'
import searchIcon from "../dashContent/images/search.svg"
import filterIcon from "../dashContent/images/filter.svg"
import navIcon from "../dashContent/images/nav.svg"
import doubleArrowLeft from "../dashContent/images/angle-double-left.svg"
import arrowLeft from "../dashContent/images/angle-left.svg"
import arrowRight from "../dashContent/images/angle-right.svg"
import doubleArrowRight from "../dashContent/images/angle-double-right.svg"
import angleRightBold from "../../pages/dashContent/images/arrowRightBold.svg"
import Footer from '../../components/Footer'


const Ticket = () => {

  const dispatch = useDispatch();
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('adminId');
  const ticket = useSelector((state)=>state.auth.ticket?.data || [])
  console.log(ticket)
  useEffect (()=>{
    dispatch(viewTicketsAdmin(userId, token))
  }, [token, userId]);
  return (
    <div className='ticket-main-container'>
      <div className='ticketHeader-container'>
        <DashboardHeader/>
      </div>
      <div className='ticket-container'>
        <div className='ticket-Header'>All Tickets</div>
        <div className='ticket-history'>
          <div className='ticket-history-searchbar'>
            <input type="text" />
            <img className='ticket-history-searchbar-img' src={searchIcon} alt="" />
            <div className='filterContainer'> 
            <img className='filterContainer-img' src={filterIcon} alt="" />
              
            </div>
          </div>
          <div className='ticket-history-labels'>
            <p>Ticket ID</p>
            <p>Passenger</p>
            <p>Amount</p>
            <p>Trip</p>
            <p>Trip Date</p>
          </div>
          <div className='ticket-list-holder'>
            {
              ticket? 
              ticket.map((tickets)=>(
                <div className='ticket-list-item' key={tickets._id}>
                <p>GIGM286GD</p>
                <p>Jane Doe</p>
                <p>{tickets.amount}</p>
                <div className='tickets-trips'>
                  <p>{tickets.from}</p>
                  <div className='ticket-list-item-img-container'>
                  <img className='ticket-list-item-img' src={angleRightBold} alt="arrow" />
                  </div>
                  <p>{tickets.to}</p>
                </div>
                <p>04 Oct 2023 | 23:18</p>
                <div className='ticket-list-item-img2-container'>
                <img  className='ticket-list-item-img2' src={navIcon} alt="nav Icon" />
                </div>
              </div>
              )) : <p>No ticket found</p>
            }
            
            
          </div>
          <div className='ticket-history-navigation'>
            <div className='ticket-history-item'>
              <img src={doubleArrowLeft} alt="" />
            </div>
            <div className='ticket-history-item2'>
              <img src={arrowLeft} alt="" />
            </div>
            <div className='ticket-history-item2-active'>
              <p>1</p>
            </div>
            <div className='ticket-history-item2'>
              <p>2</p>
            </div>
            <div className='ticket-history-item2'>
              <img src={arrowRight} alt="" />
            </div>
            <div className='ticket-history-item3'>
              <img src={doubleArrowRight} alt="" />
            </div>

        </div>
          
        </div>
       
      </div>
      <div className='Ticket-footer'>
          <Footer/>
      </div>
    </div>
  )
}

export default Ticket
