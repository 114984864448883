import React, { useState, useEffect } from 'react'
import "./styles.css"
import { IoSearchSharp } from "react-icons/io5";
import { IoFilterSharp } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { BsThreeDots } from "react-icons/bs";
import { TbBus } from "react-icons/tb";
import { GoArrowSwitch } from "react-icons/go";
import { PiCurrencyNgnBold } from "react-icons/pi";
import { viewTerminals, addTrips, viewAllVehicles, viewAllDrivers, viewTripsCompany } from '../../../../actions/authActions';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

const CompanyTrips = () => {
  const [showTrips, setShowTrips] = useState(true)
  // const [groupedTrips, setGroupedTrips] = useState({});
  const [addNewTrips, setAddNewTrips] = useState (false)
  const [viewTrips, setViewTrips] = useState(false)
  const [editTrips, setEditTrips] = useState(false)
  const [loading, setLoading] = useState(false)
  const terminals = useSelector((state) => state.auth.terminals?.data || []);
  const vehicles = useSelector((state) => state.auth.vehicles?.data || []);
  const drivers = useSelector((state) => state.auth.driver?.data || []);
  const trips = useSelector((state) => state.auth.trips?.data || []);
  const dispatch = useDispatch();
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('userId'); 
  console.log(trips)
  const [formData, setFormData] = useState({
    to:'',
    from:'',
    departureTerminal:'',
    destinationTerminal:'',
    vehicle:'',
    driver:'',
    price:''
  })

  const validateTripsData = ()=>{
    if(!formData.to){
      Swal.fire('Error', "destination location is required", 'error')
      return false;
    }
    if(!formData.from){
      Swal.fire('Error', "depature location is required", 'error')
      return false;
    }
    if(!formData.departureTerminal){
      Swal.fire('Error', "departure terminal  is required", 'error')
      return false;
    }
    if(!formData.destinationTerminal){
      Swal.fire('Error', "destination terminal is required", 'error')
      return false;
    }
    if(!formData.vehicle){
      Swal.fire('Error', "Vehicle is required", 'error')
      return false;
    }
    if(!formData.driver){
      Swal.fire('Error', "Driver is required", 'error')
      return false;
    }
    if(!formData.price){
      Swal.fire('Error', "Trip Price is require", 'error')
      return false;
    }
    return true
  };

  const handleTripsData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSHowTrips = ()=>{
    setShowTrips(true)
  }
  const handleHideTrips = ()=>{
    setShowTrips(false)
  }
  const handleAddNewTrips = ()=>{
    setAddNewTrips(true)
    setEditTrips(false)
    setViewTrips(false)
  }

  const handleSaveNewTrips = (e) => {
    e.preventDefault();
    if (validateTripsData()) {
      setLoading(true);
      dispatch(addTrips(formData, userId, token))
        .then(() => {
          Swal.fire('Success', 'Trips added succesfully', 'success');
        })
        .catch((error) => {
          Swal.fire('Error', error.message, 'error');
        })
        .finally(() => setLoading(false));
    }
    setAddNewTrips(false);
  }
  


  const handleViewTrips = ()=>{
    setViewTrips(true)
    setAddNewTrips(false)
    setEditTrips(false)
  }
  const handleEditTrips = () =>{
    setAddNewTrips(false)
    setViewTrips(false)
    setEditTrips(true)
  }
  const handleEitTripsSvBtn = ()=>{
    setViewTrips(true)
    setAddNewTrips(false)
    setEditTrips(false)
  }
  useEffect(() => {
    if (userId && token) {
      // Dispatch viewTerminals and handle errors
      dispatch(viewTerminals(userId, token))
        .catch((error) => {
          console.error('Failed to fetch terminals:', error);
        });
  
      // Dispatch viewAllVehicles and handle errors
      dispatch(viewAllVehicles(userId, token))
        .catch((error) => {
          console.error('Failed to fetch vehicles:', error);
        });
      
      dispatch(viewAllDrivers(userId, token))
        .catch((error) => {
          console.error('Failed to fetch drivers:', error);
        });

        dispatch(viewTripsCompany(userId, token))
        .catch((error) => {
          console.error('Failed to fetch trips:', error);
        });
        

    } else {
      console.warn('userId or token not found');
    }
  }, [dispatch, userId, token]);

 
  return (
    <div className='companyVehicles-mainContainer'>
        <div className="company-dash-toplayer"></div>
        {
          addNewTrips?
          <form className='addNewTrip-mainContainer' onSubmit={handleSaveNewTrips}>
          
          <h3>Add new Trips</h3>
          <div className="addTrips-section1">
              <div className="addTrips-section1a">
                <label htmlFor="">From</label>
                <div className="addTrips-section1-itemA">
                  <TbBus size={20}/>
                  <input type="text" name='from' value={formData.from} onChange={handleTripsData}/>
                </div>
              </div>
              <div className='arrowHolder'><GoArrowSwitch size={20}/></div> 
              <div className="addTrips-section1a">
                <label htmlFor="">To</label>
                <div className="addTrips-section1-itemA">
                  <TbBus size={20}/>
                  <input type="text" name='to' value={formData.to} onChange={handleTripsData} />
                </div>
              </div>
          </div>
          <div className="addTrips-section2">
          <label>Select Departure Terminal</label>
          <div className="addTrips-section1-itemA">
            <select className='addTrips-select' name='departureTerminal' value={formData.departureTerminal} onChange={handleTripsData}>
              {terminals.map((terminal) => (
                <option key={terminal._id} value={terminal._id}>{terminal.terminal}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="addTrips-section2">
          <label>Select Destination Terminal</label>
          <div className="addTrips-section1-itemA">
            <select className='addTrips-select' name='destinationTerminal' value={formData.destinationTerminal} onChange={handleTripsData}>
              {terminals.map((terminal) => (
                <option key={terminal._id} value={terminal._id}>{terminal.terminal}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="addTrips-section3">
            <label>Select Vehicle</label>
            <div className="addTrips-section1-itemA">
              <select className='addTrips-select' name='vehicle' value={formData.vehicle} onChange={handleTripsData}>
                {vehicles.map((vehicle) => (
                  <option key={vehicle._id} value={vehicle._id}>{vehicle.serialNo}</option>
                ))}
              </select>
            </div>
          </div>
              <div className="addTrips-section2">
                <label htmlFor="">Select Driver</label>
                <div className="addTrips-section1-itemA">
                  <select className='addTrips-select' name="driver" value={formData.driver} onChange={handleTripsData}>
                    
                    {drivers.map((driver)=>(
                      <option key={driver._id}  value={driver._id} >{driver.lastName} {driver.firstName}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="addTrips-section2">
                <label htmlFor="">Price</label>
                <div className="addTrips-section1-itemA">
                <PiCurrencyNgnBold size={20}/>
                <input type="text" name='price' value={formData.price} onChange={handleTripsData} />
                </div>
              </div>
             <div> <button type='submit' className='AddTripBtn'>{loading? 'submitting...' : 'Submit'}</button></div>
          </form> 

          : viewTrips? 
          <div className='viewTrips-mainContainer'>
            <h2>View trip details</h2>
            <div className="viewTrips-butttonHolder2">
              <button className='viewTrips-btn1' onClick={handleEditTrips}>Edit</button>
              <button className='viewTrips-btn2'>Delete</button>
            </div>
            <div className="viewTrips-section1">
              <div className="viewTrips-section1a">
                <label htmlFor="">From</label>
                <div className="viewTrips-section1a1">
                  <TbBus size={25}/> <p>ABUJA</p>
                </div>
              </div>
              <GoArrowSwitch size={30}/>
              <div className="viewTrips-section1a">
                <label htmlFor="">To</label>
                <div className="viewTrips-section1a1">
                  <TbBus size={25}/> <p>LAGOS</p>
                </div>
              </div>
            </div>
            <div className="viewTrips-section2">
              <h4>Departure Terminal</h4>
              <p>Jabi Terminal</p>
            </div>
            <div className="viewTrips-section2">
              <h4>Destination Terminal</h4>
              <p>Berger Terminal</p>
            </div>
            <div className="viewTrips-section2">
              <h4>Vehicle</h4>
              <p>GIGM-001- IKJ-230PA (Toyota Shuttle)</p>
            </div>
            <div className="viewTrips-section2">
              <h4>Driver</h4>
              <p>Gabriel Doe (GIGD-223909)</p>
            </div>
            <div className="viewTrips-section2">
              <h4>Price</h4>
              <p className='viewTrips-priceHolder'> <PiCurrencyNgnBold/> 12,000 </p>
            </div>
            <div className="viewTrips-butttonHolder">
              <button>Back</button>
            </div>
          </div> 
          
          : editTrips?
          <div className='addNewTrip-mainContainer'>
          
          <h3>Add new Trips</h3>
          <div className="addTrips-section1">
              <div className="addTrips-section1a">
                <label htmlFor="">From</label>
                <div className="addTrips-section1-itemA">
                  <TbBus size={20}/>
                  <input type="text" />
                </div>
              </div>
              <div className='arrowHolder'><GoArrowSwitch size={20}/></div> 
              <div className="addTrips-section1a">
                <label htmlFor="">To</label>
                <div className="addTrips-section1-itemA">
                  <TbBus size={20}/>
                  <input type="text" />
                </div>
              </div>
          </div>
          <div className="addTrips-section2">
                <label htmlFor="">Select Departure Terminal</label>
                <div className="addTrips-section1-itemA">
                  <select className='addTrips-select' name="" id="">
                    <option value="">search</option>
                  </select>
                </div>
              </div>
              <div className="addTrips-section2">
                <label htmlFor="">Select Destination Terminal</label>
                <div className="addTrips-section1-itemA">
                  <select className='addTrips-select' name="" id="">
                    <option value="">search</option>
                  </select>
                </div>
              </div>
              <div className="addTrips-section3">
                <label htmlFor="">Select Vehicle</label>
                <div className="addTrips-section1-itemA">
                  <select className='addTrips-select' name="" id="">
                    <option value="">search</option>
                  </select>
                </div>
              </div>
              <div className="addTrips-section2">
                <label htmlFor="">Select Driver</label>
                <div className="addTrips-section1-itemA">
                  <select className='addTrips-select' name="" id="">
                    <option value="">search</option>
                  </select>
                </div>
              </div>
              <div className="addTrips-section2">
                <label htmlFor="">Price</label>
                <div className="addTrips-section1-itemA">
                <PiCurrencyNgnBold size={20}/>
                <input type="text" />
                </div>
              </div>
             <div> <button className='AddTripBtn' onClick={handleEitTripsSvBtn}>Save</button></div>
          </div> 
          
          :<>
        <div className="trips-topSection">
        <h3>Trips</h3>
        <button onClick={handleAddNewTrips}>Add New</button>
        </div>
        <div className="trips-filter-holder">
          <div className='icon12'>
          <IoSearchSharp className='icon13'  size={25}/>
          </div>
            <IoFilterSharp className='icon13' size={25}/>
        </div>
        <>
        <>
             <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>Trip ID</p>
            <p className='trips-labelHolder-item2'>Terminal</p>
            <p className='trips-labelHolder-item2'>Amount</p>
            <p className='trips-labelHolder-item3'>Destination</p>
            <p className='trips-labelHolder-item2'>Dep time</p>
            <p className='trips-labelHolder-item3'>Dep Date</p>
            <p className='trips-labelHolder-item2'></p>
        </div>
            {
              trips.map((tripList)=>(
                <>

                <div className="trips-labelHolder" key={tripList._id}>
                    <p className='trips-labelHolder-item1'>{tripList.tripId}</p>
                    <p className='trips-labelHolder-item2'>{tripList?.departureTerminal?.terminal}</p>
                    <p className='trips-labelHolder-item2'>{tripList.price}</p>
                    <div className='trips-labelHolder-item3'>
                    <div className="trip-titleHolder-a2">
                    <span>{tripList.from}</span>
                    <FaArrowRight color='red' size={10}/>
                    <span>{tripList.to}</span>    
                    </div>
                    </div>
                    <p className='trips-labelHolder-item2'>{tripList.depTime}</p>
                    <p className='trips-labelHolder-item3'>{tripList.depDate}</p>
                    <div className='trips-labelHolder-item2'><BsThreeDots /></div>
                </div>
                </>
              ))
            }
       
            
            </>
        </>
        {/* <>
        <div className="trip-titleHolder">
          <div className="trip-titleHolder-a">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>
            <div className="trip-titleHolder-b">
              {
                showTrips?
                <FaAngleDown onClick={handleHideTrips} />:
              <FaAngleRight onClick={handleSHowTrips}/>
              }
              
            </div>
          </div>
        </div>
        {
          showTrips && (
            <>
             <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>Trip ID</p>
            <p className='trips-labelHolder-item2'>Terminal</p>
            <p className='trips-labelHolder-item2'>Amount</p>
            <p className='trips-labelHolder-item3'>Destination</p>
            <p className='trips-labelHolder-item2'>Dep time</p>
            <p className='trips-labelHolder-item3'>Dep Date</p>
            <p className='trips-labelHolder-item2'></p>
        </div>
        <>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots onClick={handleViewTrips}/></div>
        </div>
        </>
            
            </>
          )
        }
       

        </>
        <>
        <div className="trip-titleHolder">
          <div className="trip-titleHolder-a">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>
            <div className="trip-titleHolder-b">
              {
                showTrips?
                <FaAngleDown onClick={handleHideTrips} />:
              <FaAngleRight onClick={handleSHowTrips}/>
              }
              
            </div>
          </div>
        </div>
        {
          showTrips && (
            <>
             <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>Trip ID</p>
            <p className='trips-labelHolder-item2'>Terminal</p>
            <p className='trips-labelHolder-item2'>Amount</p>
            <p className='trips-labelHolder-item3'>Destination</p>
            <p className='trips-labelHolder-item2'>Dep time</p>
            <p className='trips-labelHolder-item3'>Dep Date</p>
            <p className='trips-labelHolder-item2'></p>
        </div>
        <>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        </>
            
            </>
          )
        }
       

        </> */}
        {/* <>
        <div className="trip-titleHolder">
          <div className="trip-titleHolder-a">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>
            <div className="trip-titleHolder-b">
              {
                showTrips?
                <FaAngleDown onClick={handleHideTrips} />:
              <FaAngleRight onClick={handleSHowTrips}/>
              }
              
            </div>
          </div>
        </div>
        {
          showTrips && (
            <>
             <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>Trip ID</p>
            <p className='trips-labelHolder-item2'>Terminal</p>
            <p className='trips-labelHolder-item2'>Amount</p>
            <p className='trips-labelHolder-item3'>Destination</p>
            <p className='trips-labelHolder-item2'>Dep time</p>
            <p className='trips-labelHolder-item3'>Dep Date</p>
            <p className='trips-labelHolder-item2'></p>
        </div>
        <>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        <div className="trips-labelHolder">
            <p className='trips-labelHolder-item1'>GIGM286GD</p>
            <p className='trips-labelHolder-item2'>Jabi</p>
            <p className='trips-labelHolder-item2'># 12,000</p>
            <div className='trips-labelHolder-item3'>
            <div className="trip-titleHolder-a2">
            <span>LAG</span>
            <FaArrowRight color='red' size={10}/>
            <span>ABJ</span>    
            </div>
            </div>
            <p className='trips-labelHolder-item2'>12,000</p>
            <p className='trips-labelHolder-item3'>04 Oct 2023</p>
            <div className='trips-labelHolder-item2'><BsThreeDots /></div>
        </div>
        </>
            
            </>
          )
        }
       

        </> */}

        </>
        }
        
    </div>
  )
}

export default CompanyTrips