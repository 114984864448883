import React from 'react'
import "./Footer.css"
import copyrightIcon from "../pages/dashContent/images/Copyright.svg"

const Footer = () => {
  return (
    <div className='dashboard-footer'>
      <img src={copyrightIcon} alt="" />
      <p>Copyright VadTrans 2024. All Rights Reserved</p>
    </div>
  )
}

export default Footer
