import React from 'react'
import searchIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/search.svg"
import filterIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/filter.svg"
import navIcon from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/nav.svg"
import doubleArrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-left.svg"
import arrowLeft from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-left.svg"
import arrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-right.svg"
import doubleArrowRight from "../../../AdminSide/pages/Dashboard/pages/dashContent/images/angle-double-right.svg"
import "./CompanyPayment.css"

const CompanyPayment = () => {
  return (
    <div className='company-payment-container'>
      <>
      
      <h3>Payments</h3>
      <div className='company-tickets-maincontainer'>
      <div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
  
</div>
</div>
<div className='clientManagement-history-labels'>
<p>Payment ID</p>
<p>Paid by</p>
<p>Amount</p>
<p>Date</p>
<p>Status</p>

</div>
<div className='clientManagement-list-holder'>
<div className='clientManagement-list-item'>
<p>GIGM286GD</p>
<p>Jane Doe</p>
<p>#12,000</p>
<p>04 Oct 2023</p>
<p className='status-button'>Successful</p>
  <div className='clientManagement-list-item-img2-container'>
  <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
  </div>
</div>

</div>
<div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
  <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
  <p>1</p>
</div>
<div className='clientManagement-history-item2'>
  <p>2</p>
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
  <img src={doubleArrowRight} alt="" />
</div>

</div>

</div>
      </div>
      
      
      </>
    </div>
  )
}

export default CompanyPayment
