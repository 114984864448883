import React from 'react'
import "./CompanyFooter.css"
import companyLogo from "../images/logoBndW.svg"
import FB4Footer from "../images/Fb4footer.svg"
import TW4footer from "../images/Tw4footer.svg"
import Li4footer from "../images/Li4footer.svg"
import IG4footer from "../images/IG4footer.svg"
import copyRight from "../images/Copyright.svg"
import { useNavigate } from 'react-router-dom'

const CompanyFooter2 = () => {
  const navigate = useNavigate()


  const navToFAQ2 =()=>{
    navigate('/faqs')
  }
  const navToTerms = ()=>{
    navigate('/termsandcondition')
  }
  return (
    <div className='company-footer-container'>
      <div className='footer-top-section'>
        <div className='footer-top-item1'>
          <img src={companyLogo} alt="" />
          <h5>Travelling made easy and seamless!</h5>
          <div className='company-footer-icon-holder'>
            <img src={FB4Footer} alt="" />
            <img src={TW4footer} alt="" />
            <img src={IG4footer} alt="" />
            <img src={Li4footer} alt="" />
          </div>
        </div>
        <div className='footer-top-item'>
          <h4>HELP AND COMMUNITIES</h4>
          <p>Partner help</p>
          <p>Partner communities</p>
        </div>
        <div className='footer-top-item'>
          <h4>USEFUL LINKS</h4>
          <p onClick={navToTerms}>Terms and Conditions</p>
          <p>Privacy Policy</p>
          <p onClick={navToFAQ2}>FAQs</p>
        </div>
        <div className='footer-top-item'>
        <h4>CONTACT US</h4>
          <p>46, Amos Olagboyin Street, Ikeja, Lagos</p>
          <p>+234-812-945-0899</p>
          <p>contact@vadtrans.com.ng</p>
        </div>
      </div>
      <div className='footer-bottom-section'>
        <img src={copyRight} alt="" />
        <p>Copyright VadTrans 2024. All Rights Reserved</p>
      </div>
    </div>
  )
}

export default CompanyFooter2
