import React, { useState, useEffect } from 'react';
import "./forgotPassword.css";
import forgotPassImg from "../../../images/forgotPassImg.png";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../../../actions/authActions';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const { error, success } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ password: '', confirmPassword:'' });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
      if (!formData.password) {
        Swal.fire('Error', 'Password is required', 'error');
        return false;
    }
    if (formData.password.length < 6) {
        Swal.fire('Error', 'Password must be at least 6 characters long', 'error');
        return false;
    }
    if (formData.password !== formData.confirmPassword) {
        Swal.fire('Error', 'Passwords do not match', 'error');
        return false;
    }
    return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            dispatch(resetPassword(formData))
                .then(() => {
                    Swal.fire('Success', 'Password Reset Successfully', 'success').then(() => {
                        navigate('/');
                    });
                })
                .catch((error) => {
                    Swal.fire('Error', error, 'error');
                })
                .finally(() => setLoading(false));
        }
    };
    return (
        <div className='forgotPassWord-container'>
            <form className="forgotPassWord-container-left" onSubmit={handleSubmit}>
                <h1>Reset Password?</h1>
                <div className="forgotPassWord-container-right-item1">
                    <label htmlFor="">Enter New Password</label>
                    <input type="password" placeholder='password' name='password' value={formData.password} onChange={handleChange} />
                </div>
                <div className="forgotPassWord-container-right-item1">
                    <label htmlFor="">Confirm Password</label>
                    <input type="password" placeholder='confirm Password' name='confirmPassword' value={formData.confirmPassword} onChange={handleChange} />
                </div>
                <button type='submit' disabled={loading}>
                    {loading ? 'Resetting...' : 'Reset'}
                </button>
            </form>

            <div className="forgotPassWord-container-right">
                <img src={forgotPassImg} alt="Forgot Password Illustration" />
            </div>
        </div>
    );
};

export default ForgotPassword;
