// import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Swal from 'sweetalert2';
// import { useSelector } from 'react-redux';

const PrivateRoute = () => {
const notAuthenticated = ()=>{
    if(!token){
        Swal.fire('Error', 'Kindly Log in to access this page', 'error')
        return <Navigate to="/" />
    }
}
  const token = localStorage.getItem('token');
  return token ? <Outlet /> : notAuthenticated();
};

export default PrivateRoute;