import React from 'react'
import "./DashbaordHeader.css"
import notificationIcon from "../../../images/notification.svg"
import user from "../../../images/userIcon.svg"

const DashboardHeader = () => {
  return (
    <div className='DashboardHeader-main-container'>
      <div>Routes</div>
      <div className='dashboard-header-notification'>
        <img src={notificationIcon} alt="" />
        <img src={user} alt="" />
        <p>Admin</p>
      </div>
    </div>
  )
}

export default DashboardHeader
