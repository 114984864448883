import React from 'react'
import "./CompanyHeader.css"
import companyLogo from "../images/logoBndW.svg"
import searcIcon from "../images/searchWhite.svg"
import { useNavigate } from 'react-router-dom'
import { BiMenuAltRight } from "react-icons/bi";

const CompanyHeader = () => {
  const navigate = useNavigate()


  const handleLoginasCompany=()=>{
    navigate("/companylogin")
  }

  return (
    <div className='companyHeader-container'>
      <img src={companyLogo} alt=""  />
      <div className='company-header-text'>
        <p>Contact Us</p>
        <p>Already Listed?</p>
        <button onClick={handleLoginasCompany}>Login</button>
        <img src={searcIcon} alt=""  />
      </div>
      <div className="company-header-text2-mobile">
          <BiMenuAltRight size={20} color='white'/>
      </div>
    </div>
  )
}

export default CompanyHeader
