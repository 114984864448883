import './App.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute'; // Ensure correct path

// Import all your components
import Dashboard from './components/AdminSide/pages/Dashboard/Dashboard';
import DashContent from './components/AdminSide/pages/Dashboard/pages/dashContent/DashContent';
import Login from "./components/AdminSide/pages/Onboarding/Login";
import Signup from "./components/AdminSide/pages/Onboarding/Signup";
import Payment from './components/AdminSide/pages/Dashboard/pages/payments/Payment';
import Faqs from './components/AdminSide/pages/Dashboard/pages/Faqs/Faqs';
import ContactInfo from './components/AdminSide/pages/Dashboard/pages/contactInfo/ContactInfo';
import CreateFaqs from './components/AdminSide/pages/Dashboard/pages/CreateFaqs/CreateFaqs';
import HomePage from './components/Company/pages/HomePage/HomePage';
import CompanyLogin from './components/Company/pages/Onboarding/CompanyLogin';
import Layout from './components/Company/pages/Layout/Layout';
import CompanySignUpPage1 from './components/Company/pages/Onboarding/CompanySignUpPage1';
import CompanyDashboard from './components/Company/pages/CompanyDasshboard/CompanyDashboard';
import FAQuestion from './components/Company/pages/FAQuestions/FAQuestion';
import Terms from './components/Company/pages/termsCondition/Terms';
import Notification from './components/Company/pages/notification/Notification';
import TravellerHomepage from './components/TravellerSide/pages/TravelerHomepage/TravellerHomepage';
import Layout2 from './components/TravellerSide/pages/Layout2/Layout2';
import LoginTraveller from './components/TravellerSide/pages/onboarding/login/LoginTraveller';
import SignupTraveller from './components/TravellerSide/pages/onboarding/signup/Signup';
import TravellerAboutUs from './components/TravellerSide/pages/travellerAbout/TravellerAboutUs';
import TravellerFAQS from './components/TravellerSide/tavellerFAQs/TravellerFAQS';
import TravellerContactUs from './components/TravellerSide/pages/TravellerContactUs/TravellerContactUs';
import TravellerProfile from './components/TravellerSide/travellerProfile/TravellerProfile';
import TravellerEditProfile from './components/TravellerSide/travellerProfile/TravellerEditProfile';
import TravellersTicket from './components/TravellerSide/pages/tavellersTicket/TravellersTicket';
import TravellerTicketDetails from './components/TravellerSide/pages/tavellersTicket/TravellerTicketDetails';
import TransportCopanies from './components/TravellerSide/pages/TransportCompany/TranportCompanies';
import TransportCompanyDetailes from './components/TravellerSide/pages/TransportCompany/TransportCompanyDetails';
import TravellerTracking from './components/TravellerSide/pages/travellerTrackings/TravellerTracking';
import TravellerNotification from './components/TravellerSide/pages/TravellerNotification/TravellerNotification';
import ForgotPassword from './components/TravellerSide/pages/onboarding/forgotPassword/ForgotPassword';
import ResetPassWordMail from './components/TravellerSide/pages/onboarding/forgotPassword/ResetPassWordMail';
import Layout3 from './components/DriverSide/Layout/Layout3';
import DriverSignup from './components/DriverSide/Pages/driverSignup/DriverSignup';
import DriverLogin from './components/DriverSide/Pages/driverLogin/DriverLogin';
import DriversResertPassWord from './components/DriverSide/Pages/driverLogin/DriversResertPassWord';
import DriverDashboard from './components/DriverSide/Pages/driverDashboard/DriverDashboard';
import DriverTripsDetail from './components/DriverSide/Pages/driverTripDetails/DriverTripsDetail';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });
    }
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Driver Side Routes */}
          <Route element={<Layout3 />}>
            <Route path='/driverregistration' element={<DriverSignup />} />
            <Route path='/driverlogin' element={<DriverLogin />} />
            <Route path='/driverresetpassword' element={<DriversResertPassWord />} />

            <Route element={<PrivateRoute />}>
               <Route path='/driverdashboard' element={<DriverDashboard />} />
               <Route path='/drivertripdetails' element={<DriverTripsDetail />} />

             </Route>
          </Route>

          <Route element={<Layout2 />}>
            {/* Public Routes */}
            <Route path='/login' element={<LoginTraveller />} />
            <Route path='/signup' element={<SignupTraveller />} />
            <Route path='/aboutus' element={<TravellerAboutUs />} />
            <Route path='/faqs' element={<TravellerFAQS />} />
            <Route path='/contactus' element={<TravellerContactUs />} />
            <Route path="/transportcompanydetails" element={<TransportCompanyDetailes />} />

            {/* <Route path='/transportcompanydetails' element={<TransportCompanyDetailes />} /> */}
            <Route path='/transportcompanies' element={<TransportCopanies />} />
            <Route path='/' element={<TravellerHomepage />} />
            <Route path='/passwordreset' element={<ForgotPassword />} />
            <Route path='/passwordresetemail' element={<ResetPassWordMail />} />
            <Route element={<PrivateRoute />}>
              <Route path='/editprofile' element={<TravellerEditProfile />} />
              <Route path='/userprofile' element={<TravellerProfile />} />
              <Route path='/travellerticketticketinfo' element={<TravellerTicketDetails />} />
              <Route path='/travellertickets' element={<TravellersTicket />} />
              <Route path='/transportTracking' element={<TravellerTracking />} />
              <Route path='/mynotifications' element={<TravellerNotification />} />
          </Route>
          
          </Route>

          <Route path='/companyside' element={<HomePage />} />
          <Route element={<Layout />}>
            <Route path='/companylogin' element={<CompanyLogin/>} />
            <Route path='/companysignuppage1' element={<CompanySignUpPage1 />} />
            
            <Route element={<PrivateRoute />}>
              <Route path='/companydashboard' element={<CompanyDashboard />} />
              <Route path='/termsandcondition' element={<Terms />} />
              <Route path='/FAQuestions' element={<FAQuestion />} />
              <Route path='/notifications' element={<Notification />} />
          </Route>
          </Route>

         
          <Route path="/adminlogin" element={<Login />} />
          <Route path="/adminsignup" element={<Signup />} /> 
          <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/contactinfo" element={<ContactInfo />} />
              <Route path='/createfags' element={<CreateFaqs />} /> 

            </Route>
            
        </Routes>
      </Router>
    </div>
  );
}

export default App;
 