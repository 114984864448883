import React from 'react'
import "./ActivityCrd.css"
import userPlus from "../images/userPlus.svg"

const ActivityCrd = () => {
  return (
    <div className='activity-card-container'>
      <div className='activity-card-container-logo'>
        <img src={userPlus} alt="" />
      </div>
      <div className='activity-card-container-text'>
        <div className='activity-card-container-text-sec1'>
          <p>New User Activity- <span>18 minutes ago</span></p>
        </div>
        <div className='activity-card-container-text-sec1'>
          <h4>2 New Users Opened Created Accounts on VadTrans</h4>
        </div>
      </div>
    </div>
  )
}

export default ActivityCrd
