import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./EmailNotification.css";
import DashboardHeader from '../../components/DashboardHeader';
import { sendEmails } from '../../../../../../actions/authActions';

const EmailNotification = () => {
  const token = localStorage.getItem('token')
  const dispatch = useDispatch();
  const [emailType, setEmailType] = useState('');
  const [emailTo, setEmailTo] = useState('');
  const [loading, setLoading] = useState(false)
  const { message, error } = useSelector((state) => state.auth);

  const [emailSubject, setEmailSubject] = useState('');
  const [content, setContent] = useState('');

  // Assuming you have selectors for users and transport companies in your Redux store
  const users = useSelector((state) => state.auth.allTravellers?.data || 'no user'); // Adjust based on your state structure
  const transportCompanies = useSelector((state) => state.transportCompanies); 

  const handleRadioChange = (type) => {
    setEmailType(type);
    if (type === 'All_Users') {
      setEmailTo(users.map(user => user.email).join(', '));
    } else if (type === 'All_Transport_Companies') {
      setEmailTo(transportCompanies.map(company => company.email).join(', '));
    } else {
      setEmailTo(''); // Clear for single user input
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      emailType,
      emailTo,
      emailSubject,
      content,
    };
    dispatch(sendEmails(formData, token)); // Adjust with actual action
  };

  return (
    <div className='email_notification-container'>
      <div className='contentHeader-container'>
        <DashboardHeader />
      </div>
      <div className="email-container-main">
        <h3>Send New Email</h3>
        <form onSubmit={handleSubmit}>
          <div className="email-container-sec1">
            <div className="email-container-sec1a">
              <input type="radio" name="emailType" onChange={() => handleRadioChange('All_Users')} /> 
              <label>All Users</label>
            </div>
            <div className="email-container-sec1a">
              <input type="radio" name="emailType" onChange={() => handleRadioChange('All_Transport_Companies')} /> 
              <label>All Transport Companies</label>
            </div>
            <div className="email-container-sec1a">
              <input type="radio" name="emailType" onChange={() => handleRadioChange('Single_User')} /> 
              <label>Single User</label>
            </div>
          </div>

          {/* Single user select */}
          {emailType === 'Single_User' && (
            <div className="email-container-sec2">
              <div className="email-container-sec1a1">
                <label>Email to</label>
                <select onChange={(e) => setEmailTo(e.target.value)} value={emailTo}>
                  <option value="">Select User</option>
                  {users.map((user) => (
                    <option key={user._id} value={user.email}>{user.email}</option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {/* Subject input */}
          <div className="email-container-sec2">
            <div className="email-container-sec1a1">
              <label>Email Subject</label>
              <input 
                type="text" 
                value={emailSubject} 
                onChange={(e) => setEmailSubject(e.target.value)} 
              />
            </div>
          </div>

          {/* Message input */}
          <div className="email-container-sec3">
            <div className="email-container-sec1a1">
              <label>Message</label>
              <textarea 
                value={content} 
                onChange={(e) => setContent(e.target.value)} 
              ></textarea>
            </div>
          </div>

          <button type="submit" className='btn'>Complete</button>
        </form>
      </div>
    </div>
  );
};

export default EmailNotification;
