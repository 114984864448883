import React, { useState } from 'react'
import "./FAQuestions.css"
import FAQimage from "../../images/quesImg.svg"


const FAQuestion = () => {
const [faQues, setFaQues] = useState(false)


 const handleFAQuesCHnage = ()=>{
  setFaQues(!faQues)
 }
  return (
    <div className='FAquestions-container'>
      <div className="FAQuestion-redbackground">FAQS</div>
      <div className='FAQues-mainContainer'>
        <div className='FAQues-leftSection'>
          <div className="FAQues-lefSec-topSec">
            <h5>Frequently Asked Questions</h5>
            <h3>Do you have any question?</h3>
            <p>Here you can find information on our services, products, technical and legal issues</p>
          </div>
          <div className="FAQues-lefSec-bottomSec">
            <div className='FAQues-items'>
            <div className="FAQues-holder">
              <p>How does VadTrans work with Transport Companies?</p> <p onClick={handleFAQuesCHnage}
              >{faQues? '-' : '+'}</p>
            </div>
            {faQues&& 
                   <div className="FAQues-answer">
                   <p>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus.</p>
                 </div>
            }
         
            </div>
            
          </div>
        </div>
        <div className='FAQues-rightSection'>
          <img src={FAQimage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default FAQuestion
