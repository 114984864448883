import React from 'react'
import "./Tracking.css"

const Tracking = () => {
  return (
    <div>
      This is Tracking Page
    </div>
  )
}

export default Tracking
