
import { Outlet } from 'react-router-dom'
import CompanyFooter from '../../components/CompanyFooter'
import CompanyHeader from '../../components/CompanyHeader2'



const Layout = ({children}) => {
  return (
    <div>
      <CompanyHeader/>
    
      <main>
      <Outlet/>
      </main>
      <CompanyFooter/>
      
    </div>
  )
}

export default Layout
