import React from 'react'
import { Outlet } from 'react-router-dom'
import CompanyHeader4 from '../../Company/components/CompanyHeader4'
import CompanyFooter from '../../Company/components/CompanyFooter'

const Layout3 = () => {
  return (
   <div>
    <CompanyHeader4/>
    <main>
    <Outlet/>
    </main>
    <CompanyFooter/>
   </div>
  )
}

export default Layout3