import React, { useState, useEffect } from 'react'
import "./travellerHomepage.css"
import bus from "../../images/bus.png"
import plus from "../../images/plus.svg"
import mixedArrow from "../../images/mixedArrow.svg"
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import Card1 from '../../components/Card1'
import sect3Img from "../../images/section3Img.svg"
import { useNavigate } from 'react-router-dom'
import motoPng from "../../images/company1.png"
import motoIcon from "../../images/busIcon.png"
import { IoArrowBack } from "react-icons/io5";
import driverPics from "../../images/driver.png"
import { LuUser2 } from "react-icons/lu";
import { CiCreditCard1 } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { CiLock } from "react-icons/ci";
import { bookTicket, fetchAvailableSeats, getAllCompany, viewTripsTraveller } from '../../../../actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { IoMdStar } from "react-icons/io";



const TravellerHomepage = () => {
  const [userSearch, setUserSearch] = useState(false)
  const [userSearchDetails, setUserSearchDetails] = useState(false)
  const [luggageInfo, setLuggageInfo] = useState(false)
  const [bookingDetails, setBookingDetails] = useState(false)
  const [bookingPayment, setBookingPayment] = useState(false)
  const transportCompanyList = useSelector((state)=>state.auth?.transportCompanies?.data || [])
  const token = useSelector((state)=>state.auth?.token)
  const userId = useSelector((state)=>state.auth?.userId)
  const tripLists = useSelector((state)=>state.auth?.trips?.data || [])
  const displayedCompanies =  transportCompanyList.slice(0, 3)
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [searchInput, setSearchInput] = useState({
    to: '',
    from:'',
    adult: 1,
    children: 0,
    date: '',
    tripTpe: 'One-Way',
    from_time: '6:01 AM'
  })

  console.log(tripLists);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyDetails = (displayedCompany) => {
    navigate('/transportcompanydetails', { state: { tranCompanyID: displayedCompany.tranCompanyID } });
  };

  


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const HandleTransportCompanies = ()=>{
    navigate('/transportcompanies')
  }

    const HnadleContactUsBtn = ()=>{
      navigate('/contactus')
    }

    const HandleAboutUsBtn = () =>{
      navigate('/aboutus')
    }
    

const handleTravellerSearchBtn = () => {
  localStorage.setItem('searchInput', 'searchInput')
  const { to, from } = searchInput;
  const results = tripLists.filter(
    (trip) =>
      trip.destinationState &&
      trip.departureLocation &&
      trip.destinationState.toLowerCase() === to.toLowerCase() &&
      trip.departureLocation.toLowerCase() === from.toLowerCase()
  );

  setFilteredTrips(results); // Schedule the state update
  setUserSearch(true);   
  console.log(results); // Log the filtered results directly
};

useEffect(() => {
  console.log(filteredTrips); // Log the updated filteredTrips whenever it changes
}, [filteredTrips]);

    const handleSearchDetails = (tripId)=>{
      dispatch(fetchAvailableSeats(tripId, token))
      setUserSearchDetails(true)
      setUserSearch(false)

    }
    const handleLuggageInfo =()=>{
      setLuggageInfo(true)
      setUserSearchDetails(false)
      setUserSearch(false)
    }
    const handleBookingDetails=()=>{
      setBookingDetails(true)
      setLuggageInfo(false)
      setUserSearchDetails(false)
      setUserSearch(false)
    }
    const handleBookingPayment = ()=>{
      setBookingPayment(true)
      setBookingDetails(false)
      setLuggageInfo(false)
      setUserSearchDetails(false)
      setUserSearch(false)
    }

    useEffect(() => {
      dispatch(getAllCompany())
              
  }, [dispatch]);

  useEffect(() => {
    dispatch(viewTripsTraveller())
            
}, [dispatch]);

useEffect(() => {
  if (searchInput && userId && token) { // Ensure values exist before dispatch
      dispatch(bookTicket({ formData: searchInput, userId, token }));
  }
}, [searchInput, userId, token, dispatch]);

  return (
    <div className='travellerSide-homepage-container'>
      <div className="traveller-homepage-hero">
        <h2>Where do you want to go?</h2>
        <div className="traveller-homepage-hero-item">
          <select>
            <option value="">one-way</option>
            <option value="">round trip</option>
          </select>
          <select name="" id="">
            <option value="">No of Passengers</option>
            <option value="">1</option>
            <option value="">2</option>
            <option value="">3</option>
            <option value="">4</option>
            <option value="">5</option>
          </select>
        </div>
        <div className="traveller-heropage-tripSearch">
          <div className='traveller-homepage-tripsearch-item'>
            <img src={bus} alt="" />
            <input type="text" name="from" value={searchInput.from} onChange={handleInputChange} placeholder='From?' />
            <img src={plus} alt="" />
          </div>
          <div className="traveller-homepage-tripsearch-item2">
            <img src={mixedArrow} alt="" />
          </div>
          <div className='traveller-homepage-tripsearch-item'>
            <img src={bus} alt="" />
            <input type="text" name="to" value={searchInput.to} onChange={handleInputChange} placeholder='To?' />
            <img src={plus} alt="" />
          </div>
          <div className='traveller-homepage-tripsearch-item3'>
            <img src={bus} alt="" />
            <input type="date" value={searchInput.date} onChange={handleInputChange} placeholder='date' />
            <div>
            <FaAngleLeft />
            <FaAngleRight />
            </div>
          </div>
          <button className='traveller-homepage-tripsearch-btn' onClick={handleTravellerSearchBtn}>Search</button>
         
        </div>
        <div className='traveller-homepage-tripsearch-item-btnHolder'>
           <button className='.traveller-homepage-tripsearch-btn' onClick={handleTravellerSearchBtn}>Search</button>
          </div>
      </div>
      {
        userSearch? 
        
        <div className='search-result-holder'>
        { filteredTrips.length > 0 ? 
          filteredTrips.map((filteredTrip)=>(
            <div className="search-result-item" onClick={() => handleSearchDetails(filteredTrip._id)} key={filteredTrip._id}>
            <div className="search-result-item-top">
              <img src={motoPng} alt="" />
              <h5>{filteredTrip.tripId}</h5>
            </div>
            <div className="search-result-item-mid">
              <div className="search-result-item-midA">
                    <h5>{filteredTrip.departureState}</h5>
                    <h4>6:30 AM</h4>
              </div>
              <div className="search-result-item-midB">
                <div className="newDot"></div>
                <img src={motoIcon} alt="" />
                <div className="newDot"></div>
              </div>
              <div className="search-result-item-midC">
              <h4>6:30 AM</h4>
              <h5>{filteredTrip.destinationState}</h5>
              </div>
            </div>
            <div className="search-result-item-btm">
              <h6>10 seats left</h6> <p>{filteredTrip.tripPrice}/person</p>
            </div>
            </div>
          )) : <p> Trip with the searched route is not available now</p>

        }
          
        </div>
        
        
        : userSearchDetails? 
        
        <div className='userSearch-details-holder'>

          <div className="userSearch-details-item1">
          <IoArrowBack size={25} />
          <h3>Select seats</h3>
          </div>
          <div className="userSearch-details-item2">
            <div className="userSearch-details-item2a">
              <div className="userSearch-details-item2a1">
                <div className="userSearch-details-item2a1a"></div> <h5>Reserved</h5>
              </div>
              <div className="userSearch-details-item2a1">
                <div className="userSearch-details-item2a1b"></div> <h5>Available</h5>
              </div>
              <div className="userSearch-details-item2a1">
                <div className="userSearch-details-item2a1c"></div> <h5>Selected</h5>
              </div>
            </div>
            <div className="userSearch-details-item2b">
                <h5>Selected Seat: Seat no 3</h5>
            </div>
            <div className="userSearch-details-item2c">
              <div className="userSearch-details-item2c1">
                <div className="userSearch-details-item2c1a">
                  <img src={driverPics} alt="" /><p>driver</p>
                </div>
                <div className="userSearch-details-item2c1b">
                  1
                </div>
                <div className="userSearch-details-item2c1c">
                  2
                </div>
                <div className="userSearch-details-item2c1b">
                  5
                </div>
                <div className="userSearch-details-item2c1c">
                  4
                </div>
                <div className="userSearch-details-item2c1d">
                  3
                </div>
                <div className="userSearch-details-item2c1b">
                  8
                </div>
                <div className="userSearch-details-item2c1b">
                  7
                </div>
                <div className="userSearch-details-item2c1d">
                  6
                </div>
              </div>
              <div className="userSearch-details-item2c1">
                <div className="userSearch-details-item2c1b">
                  11
                </div>
                <div className="userSearch-details-item2c1b">
                  10
                </div>
                <div className="userSearch-details-item2c1c">
                  9
                </div>
                <div className="userSearch-details-item2c1b">
                  14
                </div>
                <div className="userSearch-details-item2c1c">
                  13
                </div>
                <div className="userSearch-details-item2c1d">
                  12
                </div>
                <div className="userSearch-details-item2c1b">
                  17
                </div>
                <div className="userSearch-details-item2c1b">
                  16
                </div>
                <div className="userSearch-details-item2c1d">
                  15
                </div>
              </div>
            </div>
          </div>
          <div className="userSearch-details-item3">
              <button onClick={handleLuggageInfo}>Next</button>
          </div>
        </div>
        
        :luggageInfo?
         <div className='luggageInfo-mainHolder'>
              <div className="luggageInfo-mainHolder-sec1">
                <IoArrowBack/> <h3>Add Luggage Information</h3>
              </div>
              <div className="luggageInfo-mainHolder-sec2">
                <h6>ATTENTION!</h6>
                <p>For luggages, maximum weight allowed in bus is 6kg with a dimension not above 45cm x 33cm x 20cm. Personal  item bags are allowed .  1 luggage bag per passenger.</p>
              </div>
              <div className="luggageInfo-mainHolder-sec3">
                <label htmlFor="">Number of luggages</label>
                <select name="" id="">
                  <option value="">select</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                  <option value="">5</option>
                </select>
              </div>
              <div className="luggageInfo-mainHolder-sec4">
                <label htmlFor="">Description of luggage(s)</label>
                <textarea name="" id=""></textarea>
              </div>
              <div className="luggageInfo-mainHolder-sec5">
                <label htmlFor="">Upload image of luggage</label>
                <div className="luggageInfo-mainHolder-sec5-imgHlder">
                  <img src="" alt="" />
                  <img src="" alt="" />
                </div>
              </div>
              <div className="luggageInfo-mainHolder-sec6">
                <button onClick={handleBookingDetails}>Next</button>
              </div>
         </div>
         
         : bookingDetails? 
         
         <div className='bookingDetails-holder'>
          <div className="bookingDetails-holder-sec1">
            <IoArrowBack/> <h3>Booking Details</h3>
          </div>
          <div className="bookingDetails-holder-sec2">
            <h5 className='bookingDetails-title'>Your bus</h5>
            <div className="bookingDetails-holder-sec2a">
              <div className="bookingDetails-holder-sec2a1-left">
                <div className="bookingDetails-holder-sec2a1-lefta">
                  <div className="bookingDetails-holder-sec2a1-lefta-item1">
                        <img src={motoPng} alt="" />
                        <h5>#GIGM1260TYH</h5>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-lefta-item2">
                    <h6>October 2nd, 2024, Mon</h6>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-leftb">
                  <div className="bookingDetails-holder-sec2a1-leftb1">
                    <p>ABJ</p>
                    <h3>9:30PM</h3>
                  </div>
                 
                 
                  <div className="bookingDetails-holder-sec2a1-leftb1">
                    <div className="bookingDetails-holder-sec2a1-leftb-dot"></div>
                    <img src={motoIcon} alt="" />
                    <div className="bookingDetails-holder-sec2a1-leftb-dot"></div>
                  </div>
                  
                  
                  <div className="bookingDetails-holder-sec2a1-leftb1">
                       <h3>6:30AM</h3>
                       <p>LAG</p>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-leftc">
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">Travel time:</label>
                    <h6>9 hrs 29 mins</h6>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">No of seats booked:</label>
                    <h6>1 seat</h6>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">Seat no:</label>
                    <h6>Seat no 3 and 6 </h6>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-leftc1">
                    <label htmlFor="">No of luggages:</label>
                    <h6>2 luggages</h6>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-leftd">
                  Total: <h3>#11,500</h3>
                </div>
              </div>
              
              <div className="bookingDetails-holder-sec2a1-right">
                <div className="bookingDetails-holder-sec2a1-right-top">
                  <div className="bookingDetails-holder-sec2a1-right-top-1">
                    <div className="bookingDetails-holder-sec2a1-right-top-1a">
                      <label htmlFor="Fare summary">Fare summary</label>
                      <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                        <p>Adult X2</p> <p># 11,000</p>
                      </div>
                    </div>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-right-top-2">
                  <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                        <p>Tax</p> <p># 5,000</p>
                      </div>
                      <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                        <p>Coupon</p> <p>Paid</p>
                      </div>
                  </div>
                  <div className="bookingDetails-holder-sec2a1-right-top-3">
                  <div className="bookingDetails-holder-sec2a1-right-top-1aMain">
                        <p>Total</p> <h3># 11,500</h3>
                      </div>
                  </div>
                </div>
                <div className="bookingDetails-holder-sec2a1-right-btm">
                  <button onClick={handleBookingPayment}>Next</button>
                </div>
              </div>
            
            </div>
          </div>
         </div>
         : bookingPayment? 
         <div className='bookingPayment-mainContainer'>
          <div className="bookingPayment-mainContainer-top">
            <IoArrowBack/>
            <h3>Payment</h3>
          </div>
          <div className="bookingPayment-mainContainer-sec2">
              <div className="bookingPayment-mainContainer-sec2a">
                <h5>Paying your trip booking</h5>
                <div className="bookingPayment-mainContainer-sec2a1">
                  <h4>Amount to be paid :</h4> <h4 className='bookingPayment-mainContainer-sec2aPrice'># 11,500</h4>
                </div>
              </div>
          </div>
          <div className="bookingPayment-mainContainer-sec3">
            <h4>Choose payment method</h4>
            <div className="bookingPayment-mainContainer-sec3a">
              <div className="bookingPayment-mainContainer-sec3a1">
                <input type="radio" name='travellerPayment' /> <label htmlFor="">Bank Transfer</label>
              </div>
              <div className="bookingPayment-mainContainer-sec3a1">
                <input type="radio" name='travellerPayment' /> <label htmlFor="">Credit Card</label>
              </div>
            </div>
            <div className="bookingPayment-mainContainer-sec3b">
              <label htmlFor="">NAME ON CARD</label>
              <div className="bookingPayment-mainContainer-sec3b1">
                   <LuUser2 />
                   <input type="text" />
              </div>
          </div>
          <div className="bookingPayment-mainContainer-sec3b">
              <label htmlFor="">CARD NUMBER</label>
              <div className="bookingPayment-mainContainer-sec3b1">
                  <CiCreditCard1 />
                   <input type="text" placeholder='xxxx xxxx xxxx xxxx' />
              </div>
          </div>
          <div className="bookingPayment-mainContainer-sec3c">
              <div className="bookingPayment-mainContainer-sec3c1">
                    <div className="bookingPayment-mainContainer-sec3c1a">
                      <label htmlFor="">EXPIRY DATE</label>
                      <div className="bookingPayment-mainContainer-sec3b9">
                         <SlCalender />
                         <input type="text"  placeholder='MM/YY'/>
                     </div>
                    </div>
                    <div className="bookingPayment-mainContainer-sec3c1a">
                      <label htmlFor="">CVC</label>
                      <div className="bookingPayment-mainContainer-sec3b9">
                         <CiLock />
                         <input type="text" placeholder='xxx'  />
                     </div>
                    </div>
              </div>
          </div>
          </div>
          <div className="bookingPayment-mainContainer-sec4">
              <button>Next</button>
          </div>
          
         
         </div>:

        <div className="traveller-non-heroHolder">
      <div className="traveller-homepage-section2">
        <div  className='traveller-homepage-section2-top'>   
          <h2>Transportation Companies</h2>
          <div className='traveller-homepage-section2-redline'></div>
        </div>
        <div className='traveller-homepage-section2-cardholder'>
          {
            displayedCompanies.map((displayedCompany)=>(
              <div className='card1-Holder' key={displayedCompany.tranCompanyID} onClick={() => handleCompanyDetails(displayedCompany)}>
              <div className="card1-imgHolder">
              <img 
                    src={displayedCompany?.url && displayedCompany.url.trim() !== "" ? displayedCompany.url : '/comLogo.png'} 
                    alt="Company Logo" 
                    onError={(e) => { e.target.src = '/comLogo.png'; }} 
/>

              </div>
              <div className="card1-details">
                  <h2 className='card-details-title'>{displayedCompany.companyName}</h2>
                  <p className='card-details-destination'>Destinations : Lagos, Abuja, Port Harcourt, Ibadan,Enugu </p>
                  <p className='card-details-price'>Starting from #11,500</p>
                  <p className='card-details-rating'>4.6 <IoMdStar /> </p>
              </div>
                    </div>
            ))
          }
        
        </div>
        <div className="traveller-homepage-section2-btn">
           <button onClick={HandleTransportCompanies}>View More</button>
       </div>
      </div>
      <div className="traveller-homepage-section3">
      <div  className='traveller-homepage-section2-top'>   
          <h2>About VadTrans</h2>
          <div className='traveller-homepage-section2-redline'></div>
        </div>
        <div className="traveller-homepage-section3-main">
          <div className="traveller-homepage-section3-leftMain">
            <img src={sect3Img} alt="" />
          </div>
          <div className="traveller-homepage-section3-rightMain">
            <p>Vadtrans was founded on the belief that transportation should be more than just a means of getting from point A to point B. We aim to create a seamless, enjoyable, and sustainable experience that transport people from one place to another.</p>
            <p>We are committed to providing an exceptional travel experience to our customers. <br />We strive to create a great products and quality service that supersedes our customer’s taste. <br />We embrace cutting-edge technology and innovative solutions.</p>
          <span onClick={HandleAboutUsBtn}  >view more</span>
          </div>
        </div>
      </div>
      <div className="traveller-homepage-section4">
        <div className="traveller-homepage-section4-main">
          <h1>Want to know more?</h1>
          <button onClick={HnadleContactUsBtn}> Contact us</button>
        </div>
      </div>
      </div>
      }
      
    </div>
  )
}

export default TravellerHomepage
