import React, { useState } from 'react'
import "./CompanyHeader.css"
import companyLogo from "../images/logoW.svg"
import { useNavigate } from 'react-router-dom'
import driverImg from "../../TravellerSide/images/profile.jpg"
import { IoIosArrowForward } from "react-icons/io";
import { BiMenuAltRight } from "react-icons/bi";
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../../actions/authActions'

const CompanyHeader4 = () => {
  const [userIsTrue, setUserIsTrue] = useState(false)
  const dispatch = useDispatch();
  const  isAuthenticated = useSelector ((state)=>state.auth.isAuthenticated);



  const navigate = useNavigate()


  const handleLoginasCompany=()=>{
    navigate("./driverlogin")
  }

  const handleLogout2 = () => {
    dispatch(logout());
    navigate('/driverlogin');
};
 
  return (
    <div className='companyHeader-container'>
      <img src={companyLogo} alt="" />
      <div className='company-header-text2'>
        <p>Contact Us</p>

        {
          isAuthenticated?
          <div className='company-header-text2-item12'>
          <img src={driverImg} alt="" />
          User
          <IoIosArrowForward  onClick={setUserIsTrue}/>
        </div>:
          <button onClick={handleLoginasCompany}>Login</button>
        }
         {
                userIsTrue&& (
                    <div className='userProfile-option-holder2'>
                                <div className="menuItems-lists-Holder-btmSec-item2">
                                    <p className='menuItems-lists-Holder-btmSec-item2-p' 
                                    onClick={() => { handleLogout2(); setUserIsTrue(false); }}>Logout</p>
                                </div>
                            </div>
                )
            }
        
        
      </div>
      <div className="company-header-text2-mobile">
          <BiMenuAltRight size={20}/>
      </div>
    </div>
  )
}

export default CompanyHeader4
