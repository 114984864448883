import React, { useState } from 'react';
import './Signup.css';
import logo from './images/logo.svg';
import search from './images/search.svg';
import copyright from './images/Copyright.svg';
import { adminSignup } from '../../../../actions/authActions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // To navigate to login page
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
  });

  const validateForm = () => {
    if (!formData.name) {
      Swal.fire('Error', 'Name is required', 'error');
      return false;
    }
    if (!formData.email) {
      Swal.fire('Error', 'Email is required', 'error');
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      Swal.fire('Error', 'Enter a valid email', 'error');
      return false;
    }
    if (!formData.password) {
      Swal.fire('Error', 'Password is required', 'error');
      return false;
    }
    if (formData.password.length < 6) {
      Swal.fire('Error', 'Password must be at least 6 characters long', 'error');
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      dispatch(adminSignup(formData))
        .then((response) => {
          Swal.fire('Success', 'Signup successful! Please log in.', 'success');
          navigate('/adminlogin'); // Navigate to the login page after signup
        })
        .catch((error) => {
          Swal.fire('Error', 'Signup failed. Please try again.', 'error');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className='signup-mainContainer'>
      <div className='signup-Header'>
        <div className='signup-header-logo-holder'>
          <img className='signup-header-logo' src={logo} alt='' />
        </div>
        <div className='signup-btn-holder'>
          <button className='signup-btn-login'>Log In</button>
          <button className='signup-btn-signup'>Sign Up</button>
          <img className='signup-header-search' src={search} alt='' />
        </div>
      </div>
      <div className='signup-container'>
        <div className='signup-container-leftSection'>
          <img src={logo} alt='' />
          <h2>Admin Sign Up</h2>
        </div>
        <form className='signup-container-rightSection' onSubmit={handleSubmit}>
          <div className='signup-container-input'>
            <label>Full Name</label>
            <input
              type='text'
              name='name'
              value={formData.name}
              placeholder='Full Name'
              onChange={handleChange}
            />
          </div>
          <div className='signup-container-input'>
            <label>Enter email</label>
            <input
              type='text'
              name='email'
              value={formData.email}
              onChange={handleChange}
              placeholder='email'
            />
          </div>
          <div className='signup-container-input'>
            <label>Password</label>
            <input
              type='password'
              placeholder='Password'
              name='password'
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <button disabled={loading}>{loading ? 'Signing up...' : 'Sign Up'}</button>
          <div className='signup-container-createaccount'>
            <p>
              Have an account? <span>Sign in</span>
            </p>
          </div>
        </form>
      </div>
      <div className='signup-footer'>
        <img src={copyright} alt='' />
        <p>Copyright VadTrans 2024. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Signup;
