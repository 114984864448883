import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { startTrips } from '../../../../actions/authActions';
import "./drivertripDetails.css";
import motoImg from "../../../TravellerSide/images/motoLogo.png";
import motologo from "../../../TravellerSide/images/busIcon.png";
import { FaArrowRight } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const DriverTripsDetail = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { tripId } = location.state || {};
    const tripsData = useSelector((state) => state.auth.driverTrips?.data?.find(trip => trip.tripId === tripId));
    const [driverLocation, setDriverLocation] = useState(false);
    const token = localStorage.getItem('token')
    const [driverLocationBtn, setDriverLocationBtn] = useState(true);
    const [currentLocation, setCurrentLocation] = useState({
         latitude: '', 
         longitude: '' }); 
    
    const handleDriverLocationIsTrue = () => {
        // Automatically get the driver's current location when starting the trip
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            const formData = {
                latitude: latitude.toString(),
                longitude: longitude.toString()
            };
            dispatch(startTrips(formData, tripId, token));
            setDriverLocation(true);
            setDriverLocationBtn(false);
        });
    };

    return (
        <div className='driver-tripsDetails-mainCOntainer'>
            <div className="driver-tripsDetails-holder">
                <h2>Trip Details</h2>
                <div className="driver-tripsDetails-item1">
                    <div className="driver-tripsDetails-item1a">
                        <div className="driver-tripsDetails-item1a-item1">
                            <img src={motoImg} alt="" />
                            <div className="driver-tripsDetails-item1a-item1a">
                                <h4>{tripsData.transCompany[0]?.companyName}</h4>
                                <h6>{tripsData.vehicle[0]?.serialNo}</h6>
                            </div>
                        </div>
                        <div className="driver-tripsDetails-item1a-item2">
                            <img src={motologo} alt="" />
                            <h4>6:00AM</h4>
                            <div className="driver-tripsDetails-item1a-item2a">
                                <p>{tripsData.from}</p>
                                <FaArrowRight color='red' />
                                <p>{tripsData.to}</p>
                            </div>
                            <h4>9:30PM</h4>
                            <h6>12 hrs 20 mins</h6>
                        </div>
                        <div className="driver-tripsDetails-item1a-item3">
                            <h6>{tripsData.depDate}</h6>
                        </div>
                        <div className="driver-tripsDetails-item1a-item4">
                            <h5>Vehicle: {tripsData.vehicle[0]?.vehicleType}</h5>
                            <span>Serial No: {tripsData.vehicle[0]?.serialNo}</span>
                            <span>License Plate: {tripsData.vehicle[0]?.plateNumber}</span>
                        </div>
                    </div>

                    <div className="driver-tripsDetails-item1b">
                        <div className="driver-tripsDetails-item1b-item1">
                            <h4>No. of Passengers</h4>
                            <h5>30 passengers</h5>
                        </div>
                        <div className="driver-tripsDetails-item1b-item1">
                            <h4>Destination Terminal</h4>
                            <h5>{tripsData.destinationTerminal[0]?.terminal}</h5>
                        </div>
                    </div>
                </div>

                {/* Display driver location if it's available */}
                {driverLocation && 
                    <div className="driver-tripsDetails-item3">
                        <h4>Your current location</h4>
                        <div className="driver-tripsDetails-item3a">
                            <h5>Chicken Republic, 
                                4, John Holt Way, Kpata, Marine/ Kpata, Lokoja 260101, Kogi, Nigeria</h5>
                            <h4>11:59 AM</h4>
                        </div>
                    </div>
                }

                <div className="driver-tripsDetails-item2">
                    <div className="driver-tripsDetails-item2a">
                        {/* Leaflet Map */}
                        <MapContainer
                            center={[currentLocation.latitude, currentLocation.longitude]} 
                            zoom={13} 
                            style={{ height: "400px", width: "100%" }}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={[currentLocation.latitude, currentLocation.longitude]}>
                                <Popup>Current Location</Popup>
                            </Marker>
                        </MapContainer>
                    </div>

                    {/* Only show the Start Trip button if driverLocationBtn is true */}
                    {driverLocationBtn && 
                        <button onClick={handleDriverLocationIsTrue}>Start Trip</button>
                    }
                </div>

                {/* If trip has started, show the update and stop buttons */}
                {driverLocation && 
                    <div className="driver-tripsDetails-item4">
                        <button className="driver-btn1">Update Location</button>
                        <button className="driver-btn2">Stop Trip</button>
                        <button className="driver-btn3">End Trip</button>
                        <button className="driver-btn4">Continue Trip</button>
                    </div>
                }
            </div>
        </div>
    );
}

export default DriverTripsDetail;
