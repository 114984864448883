import React from 'react'
import "./Dashcontent.css"
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { viewAllTravellersAdmin, fetchAdminData, getNotificationsAdmin, viewAllCompanyAdmin, viewCommisionedBal } from '../../../../../../actions/authActions'
import notificationIcon from "./images/notification.svg"
import userIcon from "./images/userIcon.svg"
import userIcon2 from "./images/userIcon2.svg"
import busFront from "./images/busFront.svg"
import angleRight from "./images/angleRight.svg"
import ActivityCrd from './component/ActivityCrd'
import Footer from '../../components/Footer'



const DashContent = () => {
  const dispatch = useDispatch();
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('adminId');
  const travellerNo = useSelector((state)=>state.auth?.allTravellers?.data.length || 'loading')
  const companyNo = useSelector((state)=>state.auth?.transportCompanies?.data.length || 'loading')
  const adminInfo = useSelector((state)=>state.auth.userData?.data || [])
  const notifications = useSelector((state)=>state.auth.notifications?.data || [])

  useEffect (()=>{
    dispatch(viewAllTravellersAdmin(userId, token))
}, [dispatch, token, userId]);

useEffect (()=>{
  dispatch(viewCommisionedBal(token))
}, [dispatch, token]);

useEffect (()=>{
  dispatch(viewAllCompanyAdmin(userId, token))
}, [dispatch, token, userId]);

useEffect (()=>{
  dispatch(fetchAdminData(userId, token))
}, [dispatch, token, userId]);

useEffect (()=>{
  dispatch(getNotificationsAdmin(token))
}, [dispatch, token, userId]);


  return (
    <div className='Dashcontent-container'>
      <div className='dashcontent-section1'>
        <img src={notificationIcon} alt="" />
        <img src={userIcon} alt="" />
        <p>Admin</p>
      </div>
      <div className='dashontent-header'><h1>Welcome back!</h1></div>
      <div className='dashontent-card-holder'>
      <div className='dashontent-card'>
        <div className='dashontent-card-sec1'>
          <img src={userIcon2} alt="" />
          <h2>{travellerNo}</h2>
        </div>
        <div className='dashontent-card-p'>
        <p>Users</p>
        </div>
       
      </div>
      <div className='dashontent-card2'>
        <div className='dashontent-card-sec1'>
          <img src={busFront} alt="" />
          <h2>{companyNo}</h2>
        </div>
        <div className='dashontent-card-p'>
        <p>Transport Companies</p>
        </div>
       
      </div>
      
      </div>
      <div className='dashcontent-recent-activity-tag'>
          <p>Recent Activity</p>
          <div className='dashcontent-see-all'>
            <p>See all</p><img src={angleRight} alt="" />
          </div>
          
      </div>
      {notifications && notifications.length > 0 ?(
         <div className='recent-activity-container'>
         <ActivityCrd/>
         <ActivityCrd/>
         <ActivityCrd/>
         <ActivityCrd/>
 
         
       </div> 
      ) 
         : (
          <p>You don't have any notifications now.</p>
         )
      }
      
      <div className='footer'>
        <Footer/>
      </div>
    </div>
  )
}

export default DashContent
