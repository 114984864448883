import React, { useEffect, useState } from 'react'
import "./Faqs.css"
import { viewFAQsAdmin, addFAqs } from '../../../../../../actions/authActions'
import DashboardHeader from '../../components/DashboardHeader'
import searchIcon from "../dashContent/images/search.svg"
import filterIcon from "../dashContent/images/filter.svg"
import navIcon from "../dashContent/images/nav.svg"
import Footer from "../../components/Footer"
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const Faqs = () => {
const dispatch = useDispatch()
const [loading, setLoading] = useState(false)
const token = localStorage.getItem('token')
const faqs = useSelector((state)=>state.auth.faqs?.data || [])
console.log(faqs)
const [createFaqs, setCreateFaqs] = useState(true)
const [formData, setFormData] = useState({
  question:'',
  answer:''
})

const validateFormdata=()=>{
  if(!formData.question){
    Swal.fire('Error', 'Frequently asked question can not be empty', 'error')
    return false
  }
  if(!formData.answer){
    Swal.fire('Error', 'Please provide answer', 'error')
    return false
  }
  return true
}

const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });
};

const handleSubmit = (e) => {
  e.preventDefault();
  if (validateFormdata()) {
    setLoading(true);
    dispatch(addFAqs(formData, token))
      .then(() => {
        Swal.fire('Success', 'Login successful!', 'success');
        setCreateFaqs(true);
      })
      .catch((error) => {
        Swal.fire('Error', 'Login failed. Please check your credentials.', 'error');
      })
      .finally(() => setLoading(false));
  }
};
  const handleCreateFaqs=()=>{
   setCreateFaqs(false)
  }

  useEffect(()=>{
      dispatch(viewFAQsAdmin(token))
  }, [dispatch, token])
  return (
    <div className='fags-main-Container'>
       <div className='fagsHeader-container'>
        <DashboardHeader/>
      </div>

      {createFaqs ?
      
      
      <div className='faqs-container'>
      <div className='faqs-title'>
        <h3>FAQs</h3>
        <div className='faqs-button'>
          <img src="" alt="" />
          <button onClick={handleCreateFaqs}>Add New</button>
        </div>
      </div>
      <div className='fag-questions-holder'>
          <div className='faqs-searchbar'>
            <input type="text"  />
            <img src={searchIcon} alt="" />
            <div className='filterContainer'> 
            <img src={filterIcon} alt="" />
              
            </div>
          </div>
          <div className='faqs-labels'>
            <p>Questions</p>
            <p> Date Created</p>
            
          </div>
          <div className='faqs-holder' >
          {faqs.length > 0 ? (
              faqs.map((faq) => (             
                  <div className='faqs-list-item' key={faq._id}>
                    <p>{faq.question}</p>
                    <p>{faq.createdAt}</p>
                    <img src={navIcon} alt='Navigate Icon' />
                  </div>
               
              ))
            ) : (
              <p style={{padding:"2rem"}}>No FAQs available.</p>
            )}
            </div>
        </div>
      </div>
      
      
      :  <form className='faqs-container2' onSubmit={handleSubmit}>
      <div className='faqs-title'>
        <h3>Add FAQs</h3>
       </div>
       <div className='faqs-question-input-holder'> 
        <label>Question</label>
        <input type="text" name='question' value={formData.question} onChange={handleChange}/>
       </div>
       <div className='faqs-answer-input-holder'> 
        <label>Answers</label>
        <input type="text" name='answer' value={formData.answer} onChange={handleChange} />
       </div>
       <div className='faqs-complete-btnHolder'> <button type='submit' disabled={loading}>{loading? 'Submiting...' : 'Complete'}</button></div>
      </form>

      }
      
        <div className='faqs-footer'>
          
        </div>
    </div>
  )
}

export default Faqs
