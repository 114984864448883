import React, { useState } from 'react'
import "./CompanyTracking.css"
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { trackTrips } from '../../../../actions/authActions'

const CompanyTracking = () => {
  const [formData, setFormData] = useState({
    tripsIdInput:'',
  })
  const dispatch = useDispatch();
  const token = localStorage.getItem('token'); 

  const validateFormData = () => {
    if (!formData.tripsIdInput) {
      Swal.fire('Error', 'Enter valid trips Id', 'error');
      return false;
    }
    return true; // Return true if validation passes
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTrackTrips = () =>{
    if(validateFormData){
      dispatch(trackTrips(formData, token))
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    handleTrackTrips();
  };

  return (
    <div className='company-tracking-container'>
      <div className='company-dash-toplayer'></div>
      <form className="company-tracking-searchHolder" onSubmit={handleSubmit}>
        <label>Track a Trip</label>
        <input 
            type='text' 
            name='tripsIdInput' 
            value={formData.tripsIdInput} 
            onChange={handleChange} 
            placeholder='Enter Ticket ID'
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)} 
          />
          
      </form>
      <div className="company-tracking-tripDetails">
        <label>Trip Details</label>
        <p>Ticket ID : GIGM286GD</p>
      </div>
    </div>
  )
}

export default CompanyTracking
