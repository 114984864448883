import React, { useState } from "react"
import "./travellerTicketDetails.css"
import motoLogo from "../../images/motoLogo.png"
import busIcon from "../../images/busIcon.png"
import busIcon2 from "../../images/Vector.png"
import { FaArrowRight } from "react-icons/fa";

const TravellerTicketDetails = ()=>{
    const [showDetails, setShowDetail] = useState(true)

    const handleSHowDetails = ()=>{
        setShowDetail(!showDetails)
    }
    return (
        <div className="travellerTickets-main">
         <div className="travellerTicketsDetails-main">
         <div className="travellerTicketDetails-container" onClick={handleSHowDetails}>
            <div className="travellerTicket-details-item1">
                <div className="travellerTicket-details-item1a">
                    <img src={motoLogo} alt="" />
                    <div className="travellerTicket-details-item1a1">
                            <h4>God is Good Motors</h4>
                            <p>GIGM286GD</p>
                    </div>
                </div>
                <div className="travellerTicket-details-item1b">
                    <div className="travellerTicketDetails-item1b1">
                        <div className="travellerTicketDetails-item1b1a">
                                <img src={busIcon} alt="" />
                                <h5>6:00 AM</h5>
                                <p>ABJ</p>
                        </div>
                        <div className="travellerTicketDetails-item1b1a">
                                <FaArrowRight color="red"/>
                                <p>LAG</p>
                                <h5>9:30 PM</h5>
                        </div>
                        <div className="travellerTicketDetails-item1b1c">
                                
                                <p>12 hrs 20 mins</p>
                                <p>20 April 2024</p>       
                        </div>
                    </div>
                </div>
            </div>
            
           </div>
           {
            showDetails&& 
            <div className="travellerTicket-details-item1-details">
                <div className="travellerTicket-details-item1-details-item1">
                    <div className="travellerTicket-details-item1-details-item1-top">
                        <div className="travellerTicket-details-item1-details-item1-topa">
                            <div className="travellerTicket-details-item1-details-item1-topa1">
                                <p>Departure</p>
                                <p>12 hrs 20 mins</p>
                            </div>
                            <div className="travellerTicket-details-item1-details-item1-topa2">
                                <p>Lag</p>
                                <img src={busIcon} alt="" />
                                <p>ABJ</p>
                            </div>
                        </div>
                        <div className="travellerTicket-details-item1-details-item1-topb">
                            <div className="travellerTicket-details-item1-details-item1-topa1">
                                <p>Arrival</p>
                                <p>12 hrs 20 mins</p>
                            </div>
                            <div className="travellerTicket-details-item1-details-item1-topa2">
                                <p>ABJ</p>
                                <img src={busIcon} alt="" />
                                <p>Lag</p>
                            </div>
                        </div>
                    </div>
                    <div className="travellerTicket-details-item1-details-item1-btm">
                        <div className="travellerTicket-details-item1-details-item1-btm-item1">
                            <div className="travellerTicket-details-item1-details-item1-btm-item1a">
                                <img src={busIcon2} alt="" />
                                <div className="locationDot"></div>
                            </div>
                            <h5>Departs Terminal: Abuja Terminal, Kubwa</h5>
                        </div>
                        <div className="travellerTicket-details-item1-details-item1-btm-item2">
                            <div className="travellerTicket-details-item1-details-item1-btm-item1a">
                                <div className="locationDot2"></div>
                            </div>
                            <h6>Rest stop at Lokoja</h6>
                        </div>
                        <div className="travellerTicket-details-item1-details-item1-btm-item1">
                            <div className="travellerTicket-details-item1-details-item1-btm-item1a">
                                
                                <div className="locationDot"></div>
                                <img src={busIcon2} alt="" />
                            </div>
                            <h5>Departs Terminal: Abuja Terminal, Kubwa</h5>
                        </div>
                    </div>
                </div>
                <div className="travellerTicket-details-item1-details-item2">
                <div className="travellerTicket-details-item1-details-item1-top2">
                           <p> Fare summary</p>
                        </div>
                        <div className="ravellerTicket-details-item1-details-item1-btm2">
                            <div className="ravellerTicket-details-item1-details-item1-btm2-item">
                            <label htmlFor="">Fare Summary</label>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-itema">
                            <h4>Adult X1</h4> <h4># 12,000</h4>
                            </div>
                            </div>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-item">
                            
                            <div className="ravellerTicket-details-item1-details-item1-btm2-itema">
                            <label htmlFor="">Tax</label> <h4>#200</h4>
                            </div>
                            </div>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-item">
                            <label htmlFor="">Total</label>
                            <div className="ravellerTicket-details-item1-details-item1-btm2-itema">
                            <p>1 traveller</p> <h4 className="ravellerTicket-details-item1-details-item1-btm2-itema-h4"># 12,000</h4>
                            </div>
                            </div>
                        </div>
                </div>
            </div>
           }
         </div>
        </div>
    );
};

 export  default TravellerTicketDetails;