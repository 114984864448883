import React, { useState, useEffect } from 'react';
import "./forgotPassword.css";
import forgotPassImg from "../../../images/forgotPassImg.png";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from '../../../../../actions/authActions';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const { error, success } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ email: '' });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        if (!formData.email) {
            Swal.fire('Error', 'Email is required', 'error');
            return false;
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            Swal.fire('Error', 'Enter a valid email', 'error');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            dispatch(forgetPassword(formData))
                .then(() => {
                    Swal.fire('Success', 'Kindly check your email to reset your password', 'success').then(() => {
                        navigate('/');
                    });
                })
                .catch((error) => {
                    Swal.fire('Error', error, 'error');
                })
                .finally(() => setLoading(false));
        }
    };
    return (
        <div className='forgotPassWord-container'>
            <form className="forgotPassWord-container-left" onSubmit={handleSubmit}>
                <h1>Forgot Password?</h1>
                <p>Enter email address associated with this account</p>
                <div className="forgotPassWord-container-right-item1">
                    <label htmlFor="">Email</label>
                    <input type="email" placeholder='Email' name='email' value={formData.email} onChange={handleChange} />
                </div>
                <button type='submit' disabled={loading}>
                    {loading ? 'Resetting...' : 'Next'}
                </button>
            </form>

            <div className="forgotPassWord-container-right">
                <img src={forgotPassImg} alt="Forgot Password Illustration" />
            </div>
        </div>
    );
};

export default ForgotPassword;
