import React, { useState, useEffect } from 'react';
import "./driverLogin.css";
import { useNavigate } from 'react-router-dom';
import { driverLogin } from '../../../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const DriverLogin = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ email: '', password: '' });
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const { token, message, error } = useSelector((state) => state.auth);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        if (!formData.email) {
            Swal.fire('Error', 'Email is required', 'error');
            return false;
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            Swal.fire('Error', 'Enter a valid email', 'error');
            return false;
        }
        if (!formData.password) {
            Swal.fire('Error', 'Password is required', 'error');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            dispatch(driverLogin(formData))
                .finally(() => setLoading(false));
        }
    };
    
    useEffect(() => {
        if (token) {
            Swal.fire('Success', message || 'Login successful!', 'success').then(() => {
                navigate("/driverdashboard");
            });
        } else if (error) {
            Swal.fire('Error', error || 'Error logging in', 'error');
        }
    }, [token, message, error, navigate]);

    const handleDriverForgetPasswordBTN = () => {
        navigate('/driverresetpassword');
    };

    return (
        <div className='driverSignup-holder'>
            <form className="driverSignup-mainContainer2" onSubmit={handleSubmit}>
                <div className="driverSignup-mainContainer-item1a">
                    <h2>DRIVER'S LOGIN</h2>
                </div>
                <div className="driverSignup-mainContainer-item2">
                    <label htmlFor="email">Driver's Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="driverSignup-mainContainer-item2">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" value={formData.password} onChange={handleChange} />
                </div>
                <div className="driverSignup-mainContainer-item6">
                    <div className="driverSignup-mainContainer-item6a">
                        <input type="checkbox" />
                        <p>Remember me</p>
                    </div>
                    <p onClick={handleDriverForgetPasswordBTN}>Forgot password?</p>
                </div>
                <button type="submit">{loading ? 'Logging in...' : 'Login'}</button>
            </form>
        </div>
    );
};

export default DriverLogin;
