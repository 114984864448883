import React from 'react';
import "./travellersTicket.css"
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineSearch } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa6";
import { FaNairaSign } from "react-icons/fa6";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const TravellersTicket = () => {
  const navigate = useNavigate()

  const handleTicketDetailsBtn = ()=>{
      navigate("/travellerticketticketinfo")
  }
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className='TravellerTicket-holder'>
      <div className="TravellerTicket-holder-web">
      <div className="editProfile-top">
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack}/>
          <h3>Tickets</h3>
        </div>
        <div className="travellerTicket-sec1">
            <select name="" id="">
              <option value="" >Latest</option>
              <option value="">by Price</option>
              <option value="">by location</option>
            </select>
            <div className="traveller-sec1-input">
            <MdOutlineSearch />
            <input type="text" placeholder='search' />
            </div>
        </div>
        <div className="travellerTicket-sec2-holder">
              <div className="travellerTicket-sec2-top">
               <p className="travellerTicket-sec2-topItems">Ticket ID</p>
               <p className="travellerTicket-sec2-topItems">Transport Company</p>
               <p className="travellerTicket-sec2-topItems">Destination</p>
               <p className="travellerTicket-sec2-topItems">Trip Type</p>
               <p className="travellerTicket-sec2-topItems">Amount</p>
               <p className="travellerTicket-sec2-topItems">Date</p>
              </div>
              <div className="travellerTicket-item">
              <div className="p-ticketID">#PMT234569</div>
                <div className="p-TransportCompany">Peace Mass Transit</div>
                <div className="p-destination">
                  <div className='destination-item1'>
                  <p>ABJ</p>
                  <FaArrowRight color='red'/>
                  <p>LAG</p>
                  </div>
                  <div className='destination-item1'>
                  <p>LAG</p>
                  <FaArrowLeft  color='black'/>
                  <p>ABJ</p>
                  </div>
                </div>
                <div className="p-TripType">One way</div>
                <div className="p-amount"><FaNairaSign color='red' /> <p style={{color:"red"}}>12,000</p></div>
                <div className="p-date">
                    <div className='p-date-holder'>
                    <p onClick={handleTicketDetailsBtn}>View</p><IoEyeOutline cursor="pointer"/>
                    </div>
                  <p>20/09/2023</p>
                </div>
              </div>
              <div className="travellerTicket-item">
              <div className="p-ticketID">#PMT234569</div>
                <div className="p-TransportCompany">Peace Mass Transit</div>
                <div className="p-destination">
                  <div className='destination-item1'>
                  <p>ABJ</p>
                  <FaArrowRight color='red'/>
                  <p>LAG</p>
                  </div>
                  
                </div>
                <div className="p-TripType">One way</div>
                <div className="p-amount"><FaNairaSign color='red' /> <p style={{color:"red"}}>12,000</p></div>
                <div className="p-date">
                    <div className='p-date-holder'>
                    <p >View</p><IoEyeOutline cursor="pointer"/>
                    </div>
                  <p>20/09/2023</p>
                </div>
              </div>
        </div>
        <div className="travellerTicket-navigation">
          <div className="navBack">
          <MdOutlineKeyboardArrowLeft />
          </div>
          <div className="navPageNumber-holder">
            <p>1</p>
          </div>
          <div className="navForward">
          <MdOutlineKeyboardArrowRight />
          </div>
        </div>
      </div>
      <div className="TravellerTicket-holder-mobile">
      <div className="editProfile-top">
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack}/>
          <h3>Tickets</h3>
        </div>
        <div className="TravellerTicket-holder-mobile-top">
        <select name="" id="">
              <option value="" >Latest</option>
              <option value="">by Price</option>
              <option value="">by location</option>
            </select>
            <div className="TravellerTicket-holder-mobile-top-input">
            <MdOutlineSearch />
            <input type="text" placeholder='search' />
            </div>
        </div>
        <div className="TravellerTicket-holder-mobile-itemHolder">
          <div className="TravellerTicket-holder-mobile-items">
                <div className="TravellerTicket-holder-mobile-item1">
                  <div className="TravellerTicket-holder-mobile-item1a">
                    <p>Ticket ID:</p>
                    <h3>#PMT23456</h3>
                  </div>
                  <div className="TravellerTicket-holder-mobile-item1a">
                    <p>Transport Company:</p>
                    <h3>Peace Mass Transit</h3>
                  </div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Destination</p>
                  <div className='destination-item1'>
                  <p>ABJ</p>
                  <FaArrowRight color='red'/>
                  <p>LAG</p>
                  </div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Trip Type</p>
                  <p>One way</p>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Amount</p>
                  <div className="p-amount"><FaNairaSign color='red' /> <p style={{color:"red"}}>12,000</p></div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Date</p>
                  <p>20/09/23</p>
                </div>
                <div className="TravellerTicket-holder-mobile-item3">
                          <p onClick={handleTicketDetailsBtn} >View</p><IoEyeOutline cursor="pointer"/>
                </div>
          </div>
          <div className="TravellerTicket-holder-mobile-items">
                <div className="TravellerTicket-holder-mobile-item1">
                  <div className="TravellerTicket-holder-mobile-item1a">
                    <p>Ticket ID:</p>
                    <h3>#PMT23456</h3>
                  </div>
                  <div className="TravellerTicket-holder-mobile-item1a">
                    <p>Transport Company:</p>
                    <h3>Peace Mass Transit</h3>
                  </div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Destination</p>
                  <div className='destination-item1'>
                  <p>ABJ</p>
                  <FaArrowRight color='red'/>
                  <p>LAG</p>
                  </div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Trip Type</p>
                  <p>One way</p>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Amount</p>
                  <div className="p-amount"><FaNairaSign color='red' /> <p style={{color:"red"}}>12,000</p></div>
                </div>
                <div className="TravellerTicket-holder-mobile-item2">
                  <p>Date</p>
                  <p>20/09/23</p>
                </div>
                <div className="TravellerTicket-holder-mobile-item3">
                          <p onClick={handleTicketDetailsBtn}>View</p><IoEyeOutline cursor="pointer"/>
                </div>
          </div>
          
        </div>
        <div className="travellerTicket-navigation">
          <div className="navBack">
          <MdOutlineKeyboardArrowLeft />
          </div>
          <div className="navPageNumber-holder">
            <p>1</p>
          </div>
          <div className="navForward">
          <MdOutlineKeyboardArrowRight />
          </div>
        </div>
      </div>

        
    </div>
  );
};

export default TravellersTicket;
