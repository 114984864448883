import React, { useEffect, useState } from "react";
import "./travellerfqs.css";
import travFaqImg from "../images/travFAQImg.svg";
import { viewFAqs } from "../../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";

const TravellerFAQS = () => {
  const [openFAQId, setOpenFAQId] = useState(null); // Track the currently open FAQ
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.auth?.faqs?.data || []); // Default to empty array if faqs is undefined

  useEffect(() => {
    dispatch(viewFAqs());
  }, [dispatch]);

  const handleFAQuesChange = (faqId) => {
    setOpenFAQId((prevId) => (prevId === faqId ? null : faqId)); // Toggle FAQ open/close
  };

  return (
    <div className="travellerFAQs-main">
      <div className="traveller-aboutUs-topSection">
        <h1>FAQs</h1>
      </div>
      <div className="travellerFAQs-main-container">
        <div className="FAQues-mainContainer2">
          <div className="FAQues-leftSection">
            <div className="FAQues-lefSec-topSec">
              <h5>Frequently Asked Questions</h5>
              <h3>Do you have any question?</h3>
              <p>
                Here you can find information on our services, products,
                technical and legal issues.
              </p>
            </div>
            <div className="FAQues-lefSec-bottomSec">
              {faqs.length > 0 ? (
                faqs.map((faq) => (
                  <div className="FAQues-items" key={faq._id}>
                    <div className="FAQues-holder">
                      <p>{faq.question}</p>
                      <p onClick={() => handleFAQuesChange(faq._id)}>
                        {openFAQId === faq._id ? "-" : "+"}
                      </p>
                    </div>
                    {openFAQId === faq._id && (
                      <div className="FAQues-answer">
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p style={{paddingTop:'20px'}}>No FAQs available at the moment.</p>
              )}
            </div>
          </div>
          <div className="FAQues-rightSection2">
            <img src={travFaqImg} alt="FAQs Illustration" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravellerFAQS;
