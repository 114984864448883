import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { forgotPasswordDriver } from '../../../../actions/authActions'
import Swal from 'sweetalert2'

const DriversResertPassWord = () => {

    const [driverResetEmail, setDriverResetEmail] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        email:'',
    })

    const validateForm = ()=>{
        if(!formData.email){
            Swal.fire('Error', 'Email is required', 'error')
            return false
        } return true;
    }

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
    };

    const handleDriverResetBtn = (e)=>{
        e.preventDefault();
        if (validateForm()){
            setLoading(true);
            dispatch(forgotPasswordDriver(formData))
            .then((response)=>{
                if(response.message === 'Kindly check your email to reset your password'){
                    Swal.fire('Success', response.message, 'success');
                    setDriverResetEmail(true)
                } else {
                    Swal.fire('Error', response.message, 'error');
                }
            })
            .catch((error)=>{
                Swal.fire('Error', 'Error, Please input your registered mail and try again', 'error')
            })
            .finally(()=> setLoading(false));
        }
    }

    const handleDriverResetSuccess = ()=>{
        navigate('/driverlogin')
    }
  return (
    

    <div className='driverSignup-holder'>
        {
            driverResetEmail?
            <div className="driverSignup-mainContainer2">
            <div className="driverSignup-mainContainer-item1a">
                    <h2>Reset password email sent</h2>
                    <h5>A password rest email has been sent to <span> xyz@gmail.com. </span>  Click on the  link in email to change your password</h5>
                </div>
                <button onClick={handleDriverResetSuccess}>Return to Login Page</button>
            </div> 
            
            :
            
            <form className="driverSignup-mainContainer2" onSubmit={handleDriverResetBtn}>
        <div className="driverSignup-mainContainer-item1a">
                <h2>Forgot password?</h2>
                <h5>Enter email address associated with this account</h5>
            </div>
            <div className="driverSignup-mainContainer-item2">
                <label htmlFor="">Email</label>
                <input type="email" name='email' value={formData.email} onChange={handleChange}/>
            </div>
       
           
            <button type='submit'>{loading? 'sending...' : 'Send'}</button>
        </form>
        }
            

        
    </div>
  )
}

export default DriversResertPassWord