import React, { useState } from 'react'
import "./Clientmanament.css"
import DashboardHeader from '../../components/DashboardHeader'
import searchIcon from "../dashContent/images/search.svg"
import filterIcon from "../dashContent/images/filter.svg"
import navIcon from "../dashContent/images/nav.svg"
import doubleArrowLeft from "../dashContent/images/angle-double-left.svg"
import arrowLeft from "../dashContent/images/angle-left.svg"
import arrowRight from "../dashContent/images/angle-right.svg"
import doubleArrowRight from "../dashContent/images/angle-double-right.svg"
import Footer from '../../components/Footer'
import motor1 from "../../pages/dashContent/images/motor1.png"
import motor2 from "../../pages/dashContent/images/motor2.png"
import motor3 from "../../pages/dashContent/images/motor3.png"
import FB from "../dashContent/images/fb.svg"
import IG from "../dashContent/images/instagram.svg"
import TW from "../dashContent/images/twitter.svg"
import { useDispatch, useSelector } from 'react-redux'
import { approveClientMembership } from '../../../../../../actions/authActions'





const ClientManagement = () => {

  const [company, setCompany] = useState(false)
  const [customers, setCustomers]= useState(true)
  const [clientManagement, setClientManagement] = useState(true)
  const [aboutClientManagement, setAboutClientManagement] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null);
  const allTravellers = useSelector((state)=>state.auth.allTravellers?.data || [])
  const allCompanies = useSelector((state)=>state.auth.transportCompanies?.data || [])
  const token = useSelector((state)=>state.auth?.token || "N/A")
  console.log(selectedCompany)
  const dispatch = useDispatch()
  
  const transCompanyId = selectedCompany?.tranCompanyID || ''

const handleApproveCompany = ()=>{
  const formData = {
    status: "approved",
  };
  dispatch (approveClientMembership(formData, transCompanyId, token))
}

const handlePendingCompany = ()=>{
  const formData = {
    status: "declined",
  };
  dispatch (approveClientMembership(formData, transCompanyId, token))
}

const handleSuspendCompany = ()=>{
  const formData = {
    status: "suspended",
  };
  dispatch (approveClientMembership(formData, transCompanyId, token))
}

const handleDeleteCompany = ()=>{
  const formData = {
    status: "deleted",
  };
  dispatch (approveClientMembership(formData, transCompanyId, token))
}
  const handleAboutClientManagement=()=>{
    setAboutClientManagement(true)
    setClientManagement(false)
  }

  const handleComapny=()=>{
    setCompany(true)
    setCustomers(false)
  }
  const handleCustomer=()=>{
    setCompany(false)
    setCustomers(true)
  }

  return (
    <div className='clientManagement-main-container'>
      <div className='clientManagementHeader-container'>
        <DashboardHeader/>
      </div>
      {clientManagement ? 
      
      <div className='clientManagement-container'>
      <div className='clientManagement-Header'>All Clients</div>
      <div className='clientManagement-Header-holder'>
        <button className='clientmanager-btn-active' onClick={handleCustomer}>Customers</button>
        <button className='clientmanager-btn' onClick={handleComapny}>Transport Companies</button>
      </div>
      {customers? 

<div className='clientManagement-history'>
<div className='clientManagement-history-searchbar'>
<input type="text" />
<img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
<div className='filterContainer'> 
<img className='filterContainer-img' src={filterIcon} alt="" />
  
</div>
</div>
<div className='clientManagement-history-labels'>
<p>Name</p>
<p>Email Address</p>
<p>Date Created</p>

</div>
<div className='clientManagement-list-holder'>
  {allTravellers? 
  allTravellers.map((travellers)=>(
    <div className='clientManagement-list-item' key={travellers._id}>
    <p>Jane Doe</p>
    <p>{travellers.email}</p>
    <p className='clientManagement-list-itemx'>04 Oct 2023</p>
    <div className='clientManagement-list-item-img2-container'>
    <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" />
    </div>
  </div>
  )): <p> No traveller found</p>
}


</div>
<div className='clientManagement-history-navigation'>
<div className='clientManagement-history-item'>
  <img src={doubleArrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowLeft} alt="" />
</div>
<div className='clientManagement-history-item2-active'>
  <p>1</p>
</div>
<div className='clientManagement-history-item2'>
  <p>2</p>
</div>
<div className='clientManagement-history-item2'>
  <img src={arrowRight} alt="" />
</div>
<div className='clientManagement-history-item3'>
  <img src={doubleArrowRight} alt="" />
</div>

</div>

</div>
      :
      <div className='clientManagement-history'>
        <div className='clientManagement-history-searchbar'>
          <input type="text" />
          <img className='clientManagement-history-searchbar-img' src={searchIcon} alt="" />
          <div className='filterContainer'> 
          <img className='filterContainer-img' src={filterIcon} alt="" />
            
          </div>
        </div>
        <div className='clientManagement-history-labels2'>
          <div className='company-name-holder'> 
            <p></p>
          <div className='company-name'>Company Name</div>
          </div>
          <div className='Company-date'>Date Created</div>
          <div className='company-status'>Status </div>
          
        </div>
        <div className='clientManagement-list-holder'>
        {
          allCompanies ?
          allCompanies.map((allCompany)=>(
            
          <div className='clientManagement-list-item2' key={allCompany.tranCompanyID}>
          <div className='company-name-holder2'> 
            <div className='xChnage'><img src={allCompany.url} alt="logo" /></div>
            <div className='company-name2'><span>{allCompany? allCompany.companyName : 'N/A'}</span></div>
          </div>
          <div className='Company-date'>04 Oct 2023</div>
          <div className='clientManagement-list-item-btn-holder'>
                 <button>{allCompany.status}</button>
              </div>
            <div className='clientManagement-list-item-img2-container'>
            <img  className='clientManagement-list-item-img2' src={navIcon} alt="nav Icon" onClick={() => {
    setSelectedCompany(allCompany);
    handleAboutClientManagement();
}}/>
            </div>
          </div>
          
       
          )) : (<p>No registered Company found</p>)
        }
         </div>
        <div className='clientManagement-history-navigation'>
          <div className='clientManagement-history-item'>
            <img src={doubleArrowLeft} alt="" />
          </div>
          <div className='clientManagement-history-item2'>
            <img src={arrowLeft} alt="" />
          </div>
          <div className='clientManagement-history-item2-active'>
            <p>1</p>
          </div>
          <div className='clientManagement-history-item2'>
            <p>2</p>
          </div>
          <div className='clientManagement-history-item2'>
            <img src={arrowRight} alt="" />
          </div>
          <div className='clientManagement-history-item3'>
            <img src={doubleArrowRight} alt="" />
          </div>

      </div>
        
      </div>}

      
     
    </div>
    :
        
       <div className='clientManagement-container2'>
      <div className='clientManagement-Header'>Details about Company</div>
        <div className='abount-company-image-holder'>
        <img  src={selectedCompany?.url || 'N/A'} alt="" />
        </div>
        <div className='comany-name-input'>
          <label >Company Name</label>
          <input type="text" value={selectedCompany?.companyName || "N/A"} readOnly />
        </div>
        <div className='comany-about-input'>
          <label >About Company</label>
          <input type="text" value={selectedCompany?.aboutUs || "N/A"} readOnly />
        </div>
        <div className='company-image-holders'>
          <img src={motor1} alt="" />
          <img src={motor2} alt="" />
          <img src={motor3} alt="" />
        </div>
        <div className='socialMedia-Handler-holder'>
            <div className='socialMedia-handlers-item'>
              <label>Facebook</label>
              <div className='socialMedia-handlers'>
              <input type="text" value={selectedCompany?.facebook || "N/A"} readOnly />
                <img src={FB} alt="" />
              </div>
            </div>
            <div className='socialMedia-handlers-item'>
              <label>Instagram</label>
              <div className='socialMedia-handlers'>
              <input type="text" value={selectedCompany?.instagram || "N/A"} readOnly />
                <img src={IG} alt="" />
              </div>
            </div>
            <div className='socialMedia-handlers-item'>
              <label>Twitter</label>
              <div className='socialMedia-handlers'>
              <input type="text" value={selectedCompany?.twitter || "N/A"} readOnly />
                <img src={TW} alt="" />
              </div>
            </div>
        </div>
        <div className='approval-btn'>
          <button className='approve-btn' onClick={handleApproveCompany}>Approve</button>
          <button className='Decline-btn' onClick={handlePendingCompany}>Decline</button>
          <button className='Suspend-btn' onClick={handleSuspendCompany}>Suspend</button>
          <button className='delete-btn' onClick={handleDeleteCompany}>Delete</button>
          '
        </div>
       </div>

      }
      
      <div className='clientManagement-footer'>
          <Footer/>
      </div>
    </div>
  )
}

export default ClientManagement
